
import { Component, Vue } from 'vue-property-decorator';
// import ReviewUnknown from '@/components/modals/ReviewUnknown.vue';
// import ReviewUser from '@/components/modals/ReviewUser.vue';
// import ReviewShare from '@/components/modals/ReviewShare.vue';
// import ChangePassword from '@/components/modals/ChangePassword.vue';
import CartSidebar from '@/components/header/CartSidebar.vue';
import i18nDropdown from '@/components/header/i18nDropdown.vue';
import { readFooterMenuItemList, readIsLoggedIn, readMainMenuItemList, readUserProfile } from '@/store/main/getters';
import { dispatchGetAllProductsCategoriesList } from '@/store/products/actions';
import { readCompareCount, readProductsCategoriesList, readProductsInWishlist } from '@/store/products/getters';
import { dispatchGetMainMenuItemList, dispatchSearchByPhrase, dispatchUserLogOut } from '@/store/main/actions';
import { ISearchResult } from '@/interfaces';
import FeedbackForm from '@/components/modals/FeedbackForm.vue';
import { getLocalCompareProducts, getLocalSearchHistory, saveLocalSearchHistory } from '@/utils';
import { commitSetCompareCount } from '@/store/products/mutations';
import $ from 'jquery';
import SidebarMob from '@/components/sidebar/SidebarMob.vue';
import SidebarDesc from '@/components/sidebar/SidebarDesc.vue';

@Component({
    components: {
      CartSidebar,
      i18nDropdown,
      FeedbackForm,
      // ReviewUnknown,
      // ReviewUser,
      // ReviewShare,
      // ChangePassword
      SidebarMob,
      SidebarDesc,
    },
})

export default class MainHeader extends Vue {
    public searchPhrase: string = '';
    public searchResults: any = {total_results: 0, results: []};
    public searchHistory: string[] = [];
    public selectedCategory = null;
    public showSidebar = false;
    public showMenuDropdown = false;

    public async mounted() {
        await dispatchGetAllProductsCategoriesList(this.$store);
        await dispatchGetMainMenuItemList(this.$store);
        let compareList = getLocalCompareProducts();
        if (compareList) {
            compareList = JSON.parse(compareList) || [];
            // @ts-ignore
            commitSetCompareCount(this.$store, compareList.length);
        }
        let searchHistory = getLocalSearchHistory();
        if (searchHistory) {
            this.searchHistory = JSON.parse(searchHistory) || [];
        }
        let globalThis = this
        if (window.innerWidth < 991){
            $(document).mouseup( function(e){ // событие клика по веб-документу
                var div = $( "#search_input_mob" ); // тут указываем ID элемента
                console.log('asdasd')
                if ( !div.is(e.target) // если клик был не по нашему блоку
                    && div.has(e.target).length === 0 ) { // и не по его дочерним элементам
                    $('#content').removeClass("drop_shadow")
                    globalThis.searchPhrase = '';
                    globalThis.searchResults = {total_results: 0, results: []};
                }
            }); 
        } else {
            $(document).mouseup( function(e){ // событие клика по веб-документу
                var div = $( "#search_input" ); // тут указываем ID элемента
                if ( !div.is(e.target) // если клик был не по нашему блоку
                    && div.has(e.target).length === 0 ) { // и не по его дочерним элементам
                    $('#content').removeClass("drop_shadow")
                    globalThis.searchPhrase = '';
                    globalThis.searchResults = {total_results: 0, results: []};
                }
            }); 
        }
        
    };

    public hideDropdown(){
        // $('.dropdown-menu').removeClass("show")
        $('#mainMenuDropdown .dropdown-menu').toggle()
    }

    public handleCloseSearchDropdown(link) {
        if (this.searchPhrase) {
            this.searchPhrase = '';
            this.searchResults = {total_results: 0, results: []};
        }
        this.$router.push(`${link}`)
        $('#content').removeClass("drop_shadow")
    }
    public addShadowContent(){
        if ($('#content').hasClass('drop_shadow')) {
            $('#content').removeClass("drop_shadow")
        } else {
            $('#content').addClass("drop_shadow")
        }
    }
    public async handleSearchFromHistory(phrase) {
        this.searchPhrase = phrase;
        this.handleSearchByPhrase();
    }

    public async handleSearchByPhrase() {
        if (this.searchPhrase) {
            this.searchResults = await dispatchSearchByPhrase(this.$store, {search: this.searchPhrase, full_page: false});
            if (this.searchResults.total_results === 0) {
                this.searchResults = {
                    total_results: 1, results: [
                    {
                        id: 0,
                        title: this.$t('Ничего не найдено').toString(),
                        text: '',
                        link: '',
                    }
                ]};
            } else {
                if (!this.searchHistory.includes(this.searchPhrase)) {
                    this.searchHistory.unshift(this.searchPhrase);
                    saveLocalSearchHistory(JSON.stringify(this.searchHistory.slice(0, 8)));
                }
            }
        } else {
            this.searchResults = {total_results: 0, results: []};;
        }
    }

    public checkSearchKeyword(text, isTitle = false) {
        const upper = this.searchPhrase.charAt(0).toUpperCase() + this.searchPhrase.slice(1);
        const lower = this.searchPhrase.charAt(0).toLowerCase() + this.searchPhrase.slice(1);
        if (text.includes(upper)) {
            const index = text.search(upper);
            if (isTitle) {
                return text.replace(upper, `<b>${upper}</b>`);
            } else {
                return text.slice(index, index + 30).replace(upper, `<b>${upper}</b>`);
            }
        } else if (text.includes(lower)) {
            const index = text.search(lower);
            if (isTitle) {
                return text.replace(upper, `<b>${upper}</b>`);
            } else {
                return text.slice(index, index + 30).replace(lower, `<b>${lower}</b>`);
            }
        } else {
            return isTitle ? text : text.slice(0, 30);
        }
    }

    public handleLogout() {
        if (this.$refs.mainMenuDropdown) {
            // @ts-ignore
            this.$refs.mainMenuDropdown.hide(true);
        }
        dispatchUserLogOut(this.$store);
        this.$router.push('/login');
    }

    public handleChangeCatalog(category) {
        // console.log(this.$router.currentRoute.path);
        // if (this.$router.currentRoute.path.includes('/catalog/')) {
        //     this.$router.push(`/catalog/${id}`);
        //     return;
        // }
        if (category.has_children && window.innerWidth < 991){
            this.selectedCategory = category;
        } else {
            this.$router.push(`/catalog/${category.codename}`);
        }
    }

    public toggleSidebar(){
        if (this.showSidebar){
            this.showSidebar = false;
        } else {
            this.showSidebar = true;
        }
    }

    public hideMainMenuDropdown(){
        this.showMenuDropdown = false;
        $('#content').removeClass("drop_shadow")
    }
    public showMainMenuDropdown(){
        this.showMenuDropdown = true;
        $('#content').addClass("drop_shadow")
    }

    get userProfile() {
        return readUserProfile(this.$store);
    }

    get isLoggedIn() {
        return readIsLoggedIn(this.$store);
    }

    get categoriesResponse() {
        return readProductsCategoriesList(this.$store);
    }

    get footerMenuItemList() {
        return readFooterMenuItemList(this.$store);
    }

    get productsInWishlist() {
        return readProductsInWishlist(this.$store);
    }

    get compareCount() {
        return readCompareCount(this.$store);
    }

    get mainMenuList() {
        return readMainMenuItemList(this.$store);
    }

}
