
import { Component, Vue } from 'vue-property-decorator';
import { readProductsCategoriesList } from '../../store/products/getters';

@Component({
    components: {
    },
})

export default class SidebarMob extends Vue {
    public selectedCategory = null;

    public async mounted() {
    };

    public handleChangeCatalog(category) {
        // console.log(this.$router.currentRoute.path);
        // if (this.$router.currentRoute.path.includes('/catalog/')) {
        //     this.$router.push(`/catalog/${id}`);
        //     return;
        // }
        if (category.has_children && window.innerWidth < 991){
            this.selectedCategory = category;
        } else {
          this.selectedCategory = null;
          this.$router.push(`/catalog/${category.codename}`);
        }
    }

    get categoriesResponse() {
        return readProductsCategoriesList(this.$store);
    }

}
