
import { Component, Vue } from 'vue-property-decorator';
import { readProductsCategoriesList } from '@/store/products/getters';
import $ from 'jquery';

@Component({
    components: {
    },
})

export default class SidebarDesc extends Vue {
  public handleChangeCatalog(category) {
    this.$router.push(`/catalog/${category.codename}`);
  }

  get categoriesResponse() {
      return readProductsCategoriesList(this.$store);
  }
}
